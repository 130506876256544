import { PartnerBrand } from '../Interfaces/ProductPublicInterface';
import { GET_PARTNER_BRANDS } from '../Gql/Queries';
import { executeQuery } from '../Utils/HelperFunctions';

class OurPartnerBrandsService {
    async getOurPartnerBrands(): Promise<PartnerBrand[]> {
        try {
            const { data } = await executeQuery<{ partnerBrands: PartnerBrand[] }>(GET_PARTNER_BRANDS());
            return data.partnerBrands;
        } catch (error) {
            console.error('Error fetching partner brands:', error);
            throw error;
        }
    }
}

export default OurPartnerBrandsService;
