import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandling } from './ErrorHandling';

const UiLoader = React.lazy(() => import('Ui/EmrysThemeProvider').catch(ErrorHandling));

const Loader = () => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div>Loading Ui Loader...</div>}>
            <UiLoader />
        </Suspense>
    </ErrorBoundary>
);

export default Loader;
