import { GET_ALL_PRODUCT_VARIANTS_BY_PRODUCT, GET_PRODUCT_DETAILS_BY_VARIANT_CODE } from '../Gql/Queries';
import { ProductVariantInterface } from '../Interfaces/Productvariant';
import { executeQuery } from '../Utils/HelperFunctions';

class ProductVariantsService {
    async getAllProductVariantsByProduct(
        productId: string | number | null | undefined,
        page: number = 1,
        itemsPerPage: number = 30,
    ): Promise<ProductVariantInterface[]> {
        if (!productId) {
            return [];
        }

        try {
            const { data } = await executeQuery<{ productVariants: ProductVariantInterface[] }>(
                GET_ALL_PRODUCT_VARIANTS_BY_PRODUCT(page, itemsPerPage, Number(productId), 'asc'),
            );
            return data.productVariants;
        } catch (error) {
            console.error('Error fetching product variants:', error);
            throw error;
        }
    }

    async getProductDetailsByVariantCode(
        productId: string | number | null | undefined,
        page: number = 1,
        itemsPerPage: number = 30,
        code: string,
    ): Promise<ProductVariantInterface> {
        try {
            const { data } = await executeQuery<{ productVariant: ProductVariantInterface }>(
                GET_PRODUCT_DETAILS_BY_VARIANT_CODE(page, itemsPerPage, Number(productId), 'asc', code),
            );
            return data.productVariant;
        } catch (error) {
            console.error('Error fetching product variant details:', error);
            throw error;
        }
    }
}

export default ProductVariantsService;
