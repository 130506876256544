import { ObjectItem } from '../Interfaces/Filters';
import { GET_FILTER_ITEMS } from '../Gql/Queries';
import { executeQuery } from '../Utils/HelperFunctions';

class FilterService {
    async getFilterItem(): Promise<ObjectItem[] | undefined> {
        try {
            const { data } = await executeQuery<{ filterItems: ObjectItem[] }>(GET_FILTER_ITEMS());
            return data.filterItems;
        } catch (error) {
            console.error('Error fetching filter items:', error);
            throw error;
        }
    }
}

export default FilterService;
